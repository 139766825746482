


export const articles = [
    {
        img:    require('../../Images/1.png.png'),
        title:   "რელიგიური თემა თანამედროვე ქართულ სახვით ხელოვნებაში დავით სულაკაურის ფრესკა „ქორწილი კანში“",
        author:   "მარიანა ოკლეი",
        year:     "2022",
        text:     "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1.-1.png'),
        title:  "ძმები ზდანევიჩების შემოქმედება - ავანგარდული წიგნი, ფუტურიზმი და სხვა",
        year:     "2021",
        author:  "მზია ჩიხრაძე",
        text:    "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1-1.png'),
        title: "ბალეტი „ოტელო“ - სოლიკო ვირსალაძის ინტერპრეტაცია",
        year:     "2021",
        author:  "ელისო ჯულაყიძე",
        text: "1958 წელს მოსკოვში საკავშირო დეკადაზე ქართული ბალეტი სპექტაკლით „ოტელო“ წარსდგა (ილ. 1). უილიამ შექსპირის ამავე სახელწოდების..."
    },
    {
        img:    require('../../Images/1.png.png'),
        year:     "2020",
        title:   "რელიგიური თემა თანამედროვე ქართულ სახვით ხელოვნებაში დავით სულაკაურის ფრესკა „ქორწილი კანში“",
        author:   "მარიანა ოკლეი",
        text:     "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1.-1.png'),
        title:  "ძმები ზდანევიჩების შემოქმედება - ავანგარდული წიგნი, ფუტურიზმი და სხვა",
        year:     "2020",
        author:  "მზია ჩიხრაძე",
        text:    "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1-1.png'),
        title: "ბალეტი „ოტელო“ - სოლიკო ვირსალაძის ინტერპრეტაცია",
        year:     "2019",
        author:  "ელისო ჯულაყიძე",
        text: "1958 წელს მოსკოვში საკავშირო დეკადაზე ქართული ბალეტი სპექტაკლით „ოტელო“ წარსდგა (ილ. 1). უილიამ შექსპირის ამავე სახელწოდების..."
    },
    {
        img:    require('../../Images/1.png.png'),
        year:     "2019",
        title:   "რელიგიური თემა თანამედროვე ქართულ სახვით ხელოვნებაში დავით სულაკაურის ფრესკა „ქორწილი კანში“",
        author:   "მარიანა ოკლეი",
        text:     "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1.-1.png'),
        year:     "2019",
        title:  "ძმები ზდანევიჩების შემოქმედება - ავანგარდული წიგნი, ფუტურიზმი და სხვა",
        author:  "მზია ჩიხრაძე",
        text:    "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1-1.png'),
        year:     "2019",
        title: "ბალეტი „ოტელო“ - სოლიკო ვირსალაძის ინტერპრეტაცია",
        author:  "ელისო ჯულაყიძე",
        text: "1958 წელს მოსკოვში საკავშირო დეკადაზე ქართული ბალეტი სპექტაკლით „ოტელო“ წარსდგა (ილ. 1). უილიამ შექსპირის ამავე სახელწოდების..."
    },
    {
        img:    require('../../Images/1.png.png'),
        year:     "2019",
        title:   "რელიგიური თემა თანამედროვე ქართულ სახვით ხელოვნებაში დავით სულაკაურის ფრესკა „ქორწილი კანში“",
        author:   "მარიანა ოკლეი",
        text:     "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1.-1.png'),
        year:     "2019",
        title:  "ძმები ზდანევიჩების შემოქმედება - ავანგარდული წიგნი, ფუტურიზმი და სხვა",
        author:  "მზია ჩიხრაძე",
        text:    "გიორგი ჩუბინაშვილის სახელობის ქართული ხელოვნების ისტორიისა და ძეგლთა დაცვის ეროვნული კვლევითი ცენტრი"
    },
    {
        img: require('../../Images/1-1.png'),
        year:     "2019",
        title: "ბალეტი „ოტელო“ - სოლიკო ვირსალაძის ინტერპრეტაცია",
        author:  "ელისო ჯულაყიძე",
        text: "1958 წელს მოსკოვში საკავშირო დეკადაზე ქართული ბალეტი სპექტაკლით „ოტელო“ წარსდგა (ილ. 1). უილიამ შექსპირის ამავე სახელწოდების..."
    }
]