import React from 'react'
import { createContext } from 'react'

function Context() {

    
  return (
   <>
   </>
  )
}
export const SearchContext = createContext({})
export default Context