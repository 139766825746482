import React, { useContext } from 'react'
import HeaderBottom from './HeaderBottom'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'
import { SearchContext } from './Contexts/Context'
function Authors() {
    const {language} = useContext(SearchContext)
  return (
    <motion.div  
    intial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{duration: 0.5}}
    exit={{opacity: 0}}>
        <HeaderBottom/>
    <div className='Authors margin-10'>
        <header>{language ?  "ავტორებისთვის"   :  "FOR AUTHORS "}</header>
        <Helmet><title>{language ?  "ავტორებისთვის"   :  "FOR AUTHORS "}</title></Helmet>
       <div>
         <p>ავტორმა სარედაქციო საბჭოს უნდა წარუდგინოს სტატიის სრული ტექსტი ქართულ ენაზე - 5000 სიტყვამდე (დაახლოებით - 15 ნაბეჭდი გვერდი) და მისი რეზიუმე ინგლისურ ენაზე - 300-500 სიტყვა (დაახლოებით 1-1,5 გვერდი).
             ილუსტრაციების მოცულობა - საშუალოდ, 1-3 ილუსტრაცია 1 ნაბეჭდ გვერდზე</p>
         <p>სტატია რედაქციას მიეწოდება ელექტრონული სახით.</p>
         <p>ელექტრონული ვერსია უნდა გაიგზავნოს შემდეგ მისამართებზე <a href="mailto:example@email.com">editor@georgianart.ge</a> და <a href="mailto:example@email.com">research@gch-centre.ge</a></p>
       </div>
        <div>
            <p className="header">ტექსტის ფორმატი:</p>
            <p>სტატიის ელექტრონული ვერსია მოგვაწოდეთ DOC (MS-Word) ფორმატით; <br />შრიფტი - ქართული ტექსტისათვის - უნიკოდი (Sylfaen); ინგლისური ტექსტისათვის - Times New Roman; შრიფტის ზომა -12.</p>
           
        </div>
        <div>
            <p className="header">სტატიის გაფორმების წესი:</p>
            <p>სტატიის თავში მოათავსეთ შემდეგი ინფორმაცია:</p>
           <ul>
            <li>• ავტორის (ავტორების) სახელი, გვარი;</li>
            <li>• განათლება, სპეციალობა;</li>
            <li>• კვლევის ძირითადი სფერო;</li>
            <li>• ძირითადი შრომები (3-5);</li>
            <li>• სამეცნიერო ხარისხი/წოდება;</li>
            <li>• სამეცნიერო/სასწავლო დაწესებულების სრული სახელწოდება;</li>
            <li>• საკონტაქტო ინფორმაცია: ელექტრონული ფოსტა, ტელეფონის ნომერი;</li>
            <li>• სტატიის სახელწოდება - არაუმეტეს 12 სიტყვისა;</li>
            <li>• საკვანძო სიტყვები - არაუმეტეს ხუთისა.</li>
           </ul>
        </div>
        <div>
            <p className="header">
            საილუსტრაციო მასალა:
            </p>
            <ul>
                <li>• ილუსტრაცია სასურველია იყოს საშუალო ან დიდი ზომის ფაილი, მაღალი ხარისხისა და მკაფიო გამოსახულების მქონე;</li>
                <li>• დანომრილ ილუსტრაციებს თან უნდა დაერთოს სახელწოდებების/ანოტაციების ნუსხა ტექსტურ (DOC) ფაილად, სურათების რიგითი ნომრის შესაბამისად;</li>
                <li>• ილუსტრაციების ჩასმის ადგილი ტექსტში ავტორის მიერ უნდა იყოს მითითებული (შესაბამისი ნუმერაციით; ფერადად).</li>
            </ul>
        </div>
        <div>
            <p className="header">
            ავტორი ვალდებულია დაიცვას ციტირების განსაზღვრული წესი:
            </p>
            <p>ტექსტში სქოლიოს დასმისთვის არ ისარგებლოთ Microsoft WORD-ში ჩამონტაჟებული Insert Footnote-ით. ამისათვის ძირითად ტექსტში სქოლიო/შენიშვნა შესაბამის ადგილას ჩასვით შემდეგი მაგალითის მიხედვით:</p>
            <img src={require('../Images/Image 4.png')} alt="" />
        </div>
        <div>
            <p className="header">
            ბიბლიოგრაფიის დამოწმების წესები:
            </p>
            <p>წიგნის დამოწმების შემთხვევაში:</p>
            <p>ავტორის გვარი, სახელის ინიციალი; წიგნის სათაური (დახრილი შრიფტით); გამოცემის ადგილი და წელი; გვერდი. პუნქტები ერთმანეთისგან გამოიყოფა მძიმით.</p>
            <p>ჟურნალ-გაზეთების დამოწმების შემთხვევაში:</p>
            <p>ავტორის გვარი, სახელის ინიციალი; სტატიის სათაური (ჩვეულებრივი შრიფტით); გამოცემის დასახელება (დახრილი შრიფტით); გამოცემის წელი/თვე/რიცხვი, ნომერი; გვერდი. პუნქტები ერთმანეთისგან გამოიყოფა მძიმით.</p>
            <p>ინტერნეტ ვერსიის დამოწმების შემთხვევაში:</p>
            <p>ავტორის გვარი, სახელის ინიციალი; სტატიის სათაური (ჩვეულებრივი შრიფტით); საიტის მისამართი; მასალის ინტერნეტში ნახვის თარიღი. პუნქტები ერთმანეთისგან გამოიყოფა მძიმით.</p>
            <p>უცხოური ლიტერატურის დამოწმების შემთხვევაში:</p>
            <p>ყველაფერი იგივე; წიგნის/სტატიის სათაური ორიგინალურ და ქართულ ენაზე (ფრჩხილებში).</p>
            <p>• ამონარიდი ტექსტიდან უნდა იყოს თარგმნილი ქართულ ენაზე.</p>
        </div>
        <div>
            <p className="header"></p>
            <p>Ø სარედაქციო საბჭო უფლებამოსილია, თუ საჭიროდ ჩათვლის, უარი თქვას სტატიის დაბეჭდვაზე;<br /></p>
            <p>Ø ავტორი ვალდებულია, გადაამუშაოს სტატია სარედაქციო საბჭოს მოთხოვნების შესაბამისად;<br /></p>
            <p>Ø ავტორი პასუხისმგებელია სტატიის შინაარსზე, ასევე, საავტორო უფლებებისა და სამეცნიერო ეთიკის საყოველთაოდ მიღებული სხვა ნორმების დაცვაზე;<br /></p>
            <p>Ø სტატიის ავტორის პოზიცია შეიძლება არ ემთხვეოდეს სარედაქციო საბჭოს პოზიციას;<br /></p>
            <p>Ø ჟურნალის რედაქცია არ აგებს პასუხს ავტორების და/ან მესამე პირებისა და ორგანიზაციების წინაშე, თუ ისინი ამ პუბლიკაციით რაიმე ფორმით დაზარალდებიან;<br /></p>
            <p>Ø სარედაქციო საბჭო უფლებამოსილია, ჟურნალიდან ამოიღოს უკვე გამოქვეყნებული სტატია, თუ გამომჟღავნდება, რომ ამ მასალის გამოქვეყნებით დაირღვა საავტორო უფლებები და სამეცნიერო ეთიკის საყოველთაოდ მიღებული სხვა ნორმები.<br /></p>
            <p>დამატებითი ინფორმაციისათვის გთხოვთ მიმართოთ ჟურნალის რედაქციას.</p>
        </div>
        
    </div>
    </motion.div>
  )
}

export default Authors